<template>
  <div @click="activate" class="day-header mm-component" :class="{
    'col-md': programSize < 13,
    'col-md-2': programSize > 12,
    'col': programSize < 4,
    'col-sm-4 col-4': programSize > 3,
    'active': (activeDate === timestamp)
    }" 
    :id="dateId"
    :date="timestamp"
    data-cy="day-tab"
  >
    <span v-show="programSize === 1 && dayTitle">
        <p class="day-title h1">{{ dayTitle }}</p>
    </span>
    <span v-show="programSize !== 1 || !dayTitle">
      <h3 class="text-center day-short">{{ dayShort }}
        <span class="text-center day-date">{{ dayDate }}</span>
      </h3>
    </span>
  </div>
</template>
<style lang="sass" type="text/sass" scoped>
.day-title
  margin-top: 12px
  padding-left: 24px
  vertical-align: middle
  text-align: center
  padding-left: 0px
  padding-top: 28px
  color: inherit
.day-header
  padding: 20px 0px
  cursor: pointer
  border-right: 1px solid
  &.first
    border-right: none
  &.last
    border-right: none
.day-date
  line-height: 2.1rem
  font-size: 1.9rem
  font-weight: 600
  margin: 0
  margin-bottom: 12px
  color: inherit
  display: block
.day-short
  font-size: 1.4rem
  font-weight: normal
  margin: 0
  margin-top: 9px
  color: inherit
</style>
<script>
  import moment from 'moment'
  import { mapStores } from 'pinia'
  import { useAppLanguages } from '@/stores/app-languages'

  export default {
    props: {
      dayTitle: String,
      programSize: Number,
      timestamp: String,
      uniqueId: String,
      activeDate: String,
      setActiveDate: Function,
      dayFormat: String,
      showMonth: Boolean
    },
    computed: {
      ...mapStores(useAppLanguages),
      dayShort () {
        var dayTimestamp = parseInt(this.timestamp);
        let lang = this.language.iso

        if (['en', 'nl', 'fr', 'es', 'de', 'pt', 'it'].indexOf(lang) === -1) {
          lang = 'en'
        }

        moment.locale(lang)
        return moment.unix(dayTimestamp).format('dd')
      },
      dayDate () {
        const dayTimestamp = parseInt(this.timestamp)
        const momentUnix = moment.unix(dayTimestamp)
        
        return this.showMonth ? momentUnix.format(this.dayFormat) : momentUnix.format('DD')
      },
      dateId () {
        return 'dayHeader' + this.uniqueId + this.timestamp
      },
      language: function () {
        const lang = this.appLanguagesStore.active
        if (lang && lang.language_data) {
          return lang.language_data
        }
      }
    },
    methods: {
      activate () {
        this.setActiveDate(this.timestamp)
      }
    }
  }
</script>
