<template>
  <div>
    <div class="container inner program-container" :class="containerClass" ref="programContainer">
      <div class="row day-container" :ref="daysId" :id="daysId" :style="{border: `1px solid ${colors('5')}`, width: dayContainerWidth}">
        <ProgramDay :uniqueId="uniqueId"
                     v-for="(day, timestamp, index) in program"
                     :key="index"
                     :class="{'last' : index === (programSize - 1), 'first': index === 0}"
                     :programSize="programSize"
                     :timestamp="timestamp"
                     :activeDate="activeDate"
                     :setActiveDate="setActiveDate"
                     :dayTitle="dayTitle"
                     :dayFormat="dayFormat"
                     :showMonth="showMonth">
        </ProgramDay>
      </div>
      <div class="day-list" ref="dayList" :style="{ 'padding-top': dayListPaddingTop }">
        <div v-for="(day, timestamp, index) in program" :key="index" role="list">
          <div class="row" :class="{'mm-program-singleday' : programSize === 1}">
            <div class="col-12 date-header text-center" :ref="getHeaderDateRef(timestamp)" :id="getHeaderDateRef(timestamp)" data-cy="day-row">
              {{ day.date }}
            </div>
          </div>
          <ProgramItem v-for="(item, i) in day.items" :item="item" :key="i"></ProgramItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import size from 'lodash/size'
  import moment from 'moment'
  import ProgramItem from './Item'
  import ProgramDay from './Day'
  import { mapStores } from 'pinia'
  import { useAppUi } from '@/stores/app-ui'

  export default {
    components: {
      ProgramItem,
      ProgramDay
    },
    props: {
      widget: Boolean,
      program: Object|Array,
      uniqueId: String,
      scrollToActiveDateOnStart: Boolean,
      dayTitle: {
        default: null,
        type: String
      },
      pageHasContent: Boolean,
      pageHasImage: Boolean,
      dayFormat: String,
      showMonth: Boolean,
      daysHeaderAbsolute: {
        default: false,
        type: Boolean
      }
    },
    data () {
      return {
        activeDate: null,
        dayContainerWidth: 'auto',
        dayHeaderRefHeight: null
      }
    },
    mounted () {
      let now = moment()
      let initDate = now.unix()
      this.$nextTick(() => { this.dayHeaderRefHeight = this.$refs[this.daysId].clientHeight })

      if (!this.program[initDate]) {
        initDate = Object.keys(this.program)[0]
      }

      window.addEventListener('resize', this.setDayContainerWidth)

      this.setDayContainerWidth()
      if (initDate) {
        this.setActiveDate(initDate.toString(), this.scrollToActiveDateOnStart)
      }
    },
    computed: {
      ...mapStores(useAppUi),
      daysId () {
        return 'days' + this.uniqueId
      },
      programSize () {
        return size(this.program)
      },
      breakpoint () {
        return this.appUiStore.breakingPoint
      },
      containerClass () {
        let containerClass = '';

        // On the homepage we apply slightly different styling
        let {pathname} = window.location
        // TODO: change isHomepage by a better name. Now this widget is used in new places.
        if(pathname === '/' || this.daysHeaderAbsolute) {
          containerClass += 'isHomepage';
        }

        // ME-1287 - Program header on mobile should not be fixed if the page has content
        if(this.pageHasContent) {
          containerClass += 'page-has-content'
        }

        return containerClass;
      },
      dayListPaddingTop() {
        if (this.appUiStore.breakingPoint.size > 4 || !this.dayHeaderRefHeight) {
          return '0px'
        }

        return `${this.dayHeaderRefHeight + 2}px !important`
      }
    },
    methods: {
      getHeaderDateRef(timestamp) {
        return `${this.uniqueId}${timestamp}`
      },
      setActiveDate (timestamp, scroll = true) {
        if (scroll) {
          this.scrollToDate(timestamp)

          this.activeDate = timestamp
        } else {
          this.activeDate = timestamp
        }
      },
      moment (day) {
        return moment(day)
      },
      scrollToDate (timestamp) {
        const headerDateRef = this.$refs[this.getHeaderDateRef(timestamp)]
        const heightMenuDesktop = 91
        const heightMenuMobile = 65

        if (headerDateRef && headerDateRef.length) {

          let top = 0
          if (this.widget === false && window.innerWidth < 1280) {
            top = headerDateRef[0].offsetTop - this.$refs[this.daysId].clientHeight - heightMenuMobile
          } else {
            top = headerDateRef[0].offsetTop - heightMenuDesktop
          }
          window.scrollTo({ top, behavior: 'smooth' })
        }
      },
      setDayContainerWidth () {
        this.dayContainerWidth = this.breakpoint.size <= 3 && this.$refs.programContainer
          ? `${this.$refs.programContainer.offsetWidth + 3}px`
          : 'auto'
      }
    },
    beforeUnmount () {
      window.removeEventListener('resize', this.setDayContainerWidth)
    }
  }
</script>

<style lang="sass" type="text/sass" scoped>
.container
  max-width: map-get($container-max-widths, "xl")

.day-container
  min-height: 135px
.mm-program-singleday
  opacity: 0
  height: 0
  overflow: hidden
.day-container
  flex-wrap: nowrap
  overflow-x: hidden
  z-index: 1
  position: -webkit-sticky
  position: sticky
  width: auto
@include media-breakpoint-down(lg)
  .program-container
    padding-left: 18px
    padding-right: 18px
    width: 100%
  .day-container
    position: fixed
    width: 100%
    overflow-x: auto !important
  .isHomepage
    .day-container
      position: absolute !important
  .page-has-content
    .day-container
      position: initial !important
  .page-has-content
    .day-list
      padding-top: 0px

.date-header
  border-right: 1px solid
  border-left: 1px solid
  font-size: 0.89rem
  padding: 5px 0px
  font-weight: 600

@include media-breakpoint-down(md)
  .item:last-of-type
    border-bottom: none
</style>
